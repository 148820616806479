import * as React from 'react';
import { CHARTS } from '../constants/CHARTS';
import ErrorBoundary from './ErrorBoundary';
import { IGaugeChartProps } from '../interfaces/IGaugeChart';

class GaugeChart extends React.PureComponent<IGaugeChartProps, {}> {
	renderGaugeLabel = (label: string, fontSize: number, x: number, y: number) => {
		return (
			<text
				fill='#000000'
				fontSize={ fontSize }
				x={ x }
				y={ y }>
				{ label }
			</text>
		);
	}

	renderGaugeMeter = (height: number, width: number) => {
		const cx = width * 0.5;
		const cy = height * 0.9;
		const RADIAN = Math.PI / 180;
		const sin45 = Math.sin(RADIAN * 45);
		const sin60 = Math.sin(RADIAN * 60);
		const cos60 = Math.cos(RADIAN * 60);
		const radius = height / 1.75;
		const x1 = cx + radius;
		const x2 = cx + radius * cos60;
		const x3 = cx - radius * cos60;
		const x4 = cx - radius;
		const y1 = cy - radius * sin60;
		const strokeWidth = width * 0.1;

		return (
			<ErrorBoundary>
				<defs>
					<linearGradient
						id='green-yellow'
						gradientUnits='objectBoundingBox'
						x1='1'
						y1='1'
						x2='0'
						y2='0'>
						<stop offset='0%' stopColor='#06D186'/>
						<stop offset='100%' stopColor='#fffb00'/>
					</linearGradient>

					<linearGradient
						id='yellow-orange'
						gradientUnits='objectBoundingBox'
						x1='1'
						y1='0'
						x2='0'
						y2='0'>
						<stop offset='0%' stopColor='#fffb00'/>
						<stop offset='100%' stopColor='#FFC102'/>
					</linearGradient>

					<linearGradient
						id='orange-red'
						gradientUnits='objectBoundingBox'
						x1='1'
						y1='0'
						x2='0'
						y2='1'>
						<stop offset='0%' stopColor='#FFC102'/>
						<stop offset='100%' stopColor='#E53F3F'/>
					</linearGradient>
				</defs>

				<g fill='none' strokeWidth={ strokeWidth }>
					<path d={ `M ${x2},${y1} A ${radius},${radius} 0 0,1 ${x1},${cy}` } stroke='url(#green-yellow)'/>
					<path d={ `M ${x3},${y1} A ${radius},${radius} 0 0,1 ${x2},${y1}` } stroke='url(#yellow-orange)'/>
					<path d={ `M ${x4},${cy} A ${radius},${radius} 0 0,1 ${x3},${y1}` } stroke='url(#orange-red)'/>
				</g>

				{ this.renderGaugeLabel('100%', 10, (x1 + 10), cy) }
				{ this.renderGaugeLabel('75%', 10, (cx + 10 + radius * sin45), (cy - 5 - radius * sin45)) }
				{ this.renderGaugeLabel('50%', 10, (cx - 5), (cy - radius - 10)) }
				{ this.renderGaugeLabel('25%', 10, (cx - 25 - radius * sin45), (cy - 5 - radius * sin45)) }
				{ this.renderGaugeLabel('0%', 10, (x4 - 25), cy) }
			</ErrorBoundary>
		);
	}

	renderNeedle = (pointValue: number, height: number, width: number) => {
		const cx = width * 0.5;
		const cy = height * 0.9;
		const needleAngle = ((100 - pointValue) / 100) * 180;
		const RADIAN = Math.PI / 180;
		const sin = Math.sin(-RADIAN * needleAngle);
		const cos = Math.cos(-RADIAN * needleAngle);
		const outerRadius = width * 0.15;
		const mx = cx + outerRadius * cos;
		const my = cy + outerRadius * sin;
		const circleWidth = width * 0.01;
		const needleDirection = `M ${cx},${cy} L ${mx},${my}`;
		const needleWidth = circleWidth * 0.8;

		return (
			<g>
				<circle
					cx={ cx }
					cy={ cy }
					r={ circleWidth }
					fill='#666'
					stroke='none' />

				<path
					d={ needleDirection }
					fill='none'
					strokeWidth={ needleWidth }
					stroke='#666'
					strokeLinecap='round' />
			</g>
		);
	}

	render() {
		const { data: chartValue } = this.props;
		const { GAUGE_CHART } = CHARTS;
		const { height } = GAUGE_CHART.GRAPH_SIZE;
		const width = height * 2.5;

		return (
			<svg height={ height } width={ width }>
				{ this.renderGaugeMeter(height, width) }

				{ this.renderNeedle(chartValue, height, width) }
			</svg>
		);
	}
}

export default GaugeChart;
