import {
	applyMiddleware,
	createStore,
	Store
} from 'redux';
import { createLogger } from 'redux-logger';
import createSageMiddleware from 'redux-saga';
import { IAppAction, IAppState } from './types';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSageMiddleware();
let store: Store<IAppState, IAppAction>;

store = createStore<IAppState, IAppAction, { dispatch: {}; }, {}>(
	reducers,
	applyMiddleware(
		createLogger({
			collapsed: true
		}),
		sagaMiddleware
	)
);

sagaMiddleware.run(sagas);

export default store;
