import { combineReducers } from 'redux';
import {
	deploymentsState,
	emulatorState,
	mapboxState,
	podsState,
	sitesState,
	telemetryStreamState
} from './states';
import {
	DEPLOYMENTS_ACTION_TYPES,
	EMULATOR_ACTION_TYPES,
	MAPBOX_ACTION_TYPES,
	PODS_ACTION_TYPES,
	SITES_ACTION_TYPES,
	TELEMETRY_STREAM_ACTION_TYPES
} from './action_types';
import {
	IAppAction,
	IAppState,
	IDeploymentsAction,
	IDeploymentsState,
	IEmulatorAction,
	IEmulatorState,
	IMapboxAction,
	IMapboxState,
	IPodsAction,
	IPodsState,
	ISitesAction,
	ISitesState,
	ITelemetryStreamAction,
	ITelemetryStreamState
} from './types';
import { getUpdatedTelemetryStream, smartPop } from './lib';

// Deployments Reducer

const deploymentsReducer = (state: IDeploymentsState = deploymentsState, action: IDeploymentsAction) => {
	const {
		deploymentSlug,
		podSlug,
		type
	} = action;

	switch (type) {
		case DEPLOYMENTS_ACTION_TYPES.APPEND_POD:
			return {
				...state,
				deployments: JSON.parse(JSON.stringify(state.deployments)).map((deployment) => {
					if (deployment.slug === deploymentSlug) {
						deployment.pods.push(podSlug);
					}

					return deployment;
				})
			};
		case DEPLOYMENTS_ACTION_TYPES.POP_POD:
			return {
				...state,
				deployments: JSON.parse(JSON.stringify(state.deployments)).map((deployment) => {
					if (deployment.slug === deploymentSlug) {
						deployment.pods = smartPop(deployment.pods, podSlug);
					}

					return deployment;
				})
			};
		default:
			return state;
	}
};

// Emulator Reducer

const emulatorReducer = (state: IEmulatorState = emulatorState, action: IEmulatorAction) => {
	const { type } = action;

	switch (type) {
		case EMULATOR_ACTION_TYPES.RESET_EMULATOR:
			return emulatorState;
		case EMULATOR_ACTION_TYPES.PAUSE_EMULATOR:
			return {
				...state,
				emulatingGrid: false
			};
		case EMULATOR_ACTION_TYPES.START_EMULATOR:
			return {
				...state,
				emulatingGrid: true
			};
		default:
			return state;
	}
};

// Mapbox Reducer

export const mapboxReducer = (state: IMapboxState = mapboxState, action: IMapboxAction) => {
	const {
		mapRef,
		type
	} = action;

	switch (type) {
		case MAPBOX_ACTION_TYPES.SET_MAP_REF:
			return { ...state, mapRef };
		default:
			return state;
	}
};

// Pods Reducer

const podsReducer = (state: IPodsState = podsState, action: IPodsAction) => {
	const {
		age,
		pod,
		podSlug,
		status,
		type
	} = action;
	let updatedPods = [];

	switch (type) {
		case PODS_ACTION_TYPES.ADD_POD:
			return {
				...state,
				pods: [ ...state.pods, pod ]
			};
		case PODS_ACTION_TYPES.SET_POD_STATUS:
			updatedPods = state.pods.map((pod) => {
				if (pod.slug === podSlug) {
					pod.status = status;
				}

				return pod;
			});

			return {
				...state,
				pods: updatedPods
			};
		case PODS_ACTION_TYPES.SET_POD_AGE:
			updatedPods = state.pods.map((pod) => {
				if (pod.slug === podSlug) {
					pod.age = age;
				}

				return pod;
			});

			return {
				...state,
				pods: updatedPods
			};
		case PODS_ACTION_TYPES.REMOVE_POD:
			return {
				...state,
				pods: state.pods.filter((pod) => pod.slug !== podSlug)
			};
		default:
			return state;
	}
};

// Sites Reducer

const sitesReducer = (state: ISitesState = sitesState, action: ISitesAction) => {
	const {
		active,
		charge,
		degrading,
		egress,
		ingress,
		latency,
		maxCharge,
		maxLatency,
		minCharge,
		minLatency,
		receivingWorkload,
		siteSlug,
		terminatingWorkload,
		tick,
		timestamp,
		type,
		upsStatus
	} = action;

	switch (type) {
		case SITES_ACTION_TYPES.APPEND_NETWORK_LATENCY_TICK:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						const { entries } = site.networkLatency;

						if (entries.length >= 30) {
							entries.shift();
						}

						entries.push({ label: timestamp, latency });
					}

					return site;
				})
			};
		case SITES_ACTION_TYPES.APPEND_PORT_UTILIZATION_TICK:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						const { entries } = site.portUtilization;

						if (entries.length >= 30) {
							entries.shift();
						}

						entries.push({ label: timestamp, ingress, egress });
					}

					return site;
				})
			};
		case SITES_ACTION_TYPES.DISCHARGE_UPS:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						const upsCharge = Number(site.upsCharge) - tick / 2;

						site.upsCharge = (upsCharge < minCharge) ? minCharge : upsCharge;
					}

					return site;
				})
			};
		case SITES_ACTION_TYPES.RECHARGE_UPS:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						const upsCharge = Number(site.upsCharge) + tick;

						site.upsCharge = (upsCharge > maxCharge) ? maxCharge : upsCharge;
					}

					return site;
				})
			};
		case SITES_ACTION_TYPES.SET_ACTIVE:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						site.active = active;
					}

					return site;
				})
			};
		case SITES_ACTION_TYPES.SET_DEGRADING_LATENCY:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						site.degradingLatency = degrading;
					}

					return site;
				})
			};
		case SITES_ACTION_TYPES.SET_DEGRADING_POWER:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						site.degradingPower = degrading;
					}

					return site;
				})
			};
		case SITES_ACTION_TYPES.SET_RECEIVING_WORKLOAD:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						site.receivingWorkload = receivingWorkload;
						site.runningWorkload = true;
					}

					return site;
				})
			};
		case SITES_ACTION_TYPES.SET_TERMINATING_WORKLOAD:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						site.terminatingWorkload = terminatingWorkload;
						site.runningWorkload = false;
					}

					return site;
				})
			};
		case SITES_ACTION_TYPES.SET_UPS_CHARGE:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						site.upsCharge = charge;
					}

					return site;
				})
			};
		case SITES_ACTION_TYPES.SET_UPS_STATUS:
			return {
				...state,
				sites: JSON.parse(JSON.stringify(state.sites)).map((site) => {
					if (site.slug === siteSlug) {
						site.upsStatus = upsStatus;
					}

					return site;
				})
			};
		default:
			return state;
	}
};

// Telemetry Stream Reducer

const telemetryStreamReducer = (state: ITelemetryStreamState = telemetryStreamState, action: ITelemetryStreamAction) => {
	const {
		telemetry,
		type
	} = action;

	switch (type) {
		case TELEMETRY_STREAM_ACTION_TYPES.APPEND_TELEMETRY_DATA:
			return {
				...state,
				telemetryStream: getUpdatedTelemetryStream(state.telemetryStream, telemetry)
			};
		case TELEMETRY_STREAM_ACTION_TYPES.RESET_TELEMETRY_STREAM:
			return state;
		default:
			return state;
	}
};

const reducers = combineReducers<IAppState, IAppAction>({
	deploymentsState: deploymentsReducer,
	emulatorState: emulatorReducer,
	mapboxState: mapboxReducer,
	podsState: podsReducer,
	sitesState: sitesReducer,
	telemetryStreamState: telemetryStreamReducer
});

export default reducers;
