// Deployments Action Types

export const DEPLOYMENTS_ACTION_TYPES = {
	APPEND_POD: 'append_pod',
	POP_POD: 'pop_pod',
	RESUME_EMULATOR: 'resume_emulator',
	START_EMULATOR: 'start_emulator'
};

// Emulator Action Types

export const EMULATOR_ACTION_TYPES = {
	PAUSE_EMULATOR: 'pause_emulator',
	RESET_EMULATOR: 'reset_emulator',
	RESUME_EMULATOR: 'resume_emulator',
	START_EMULATOR: 'start_emulator'
};

// Mapbox Action Types

export const MAPBOX_ACTION_TYPES = {
	SET_MAP_REF: 'set_map_ref'
};

// Pods Action Types

export const PODS_ACTION_TYPES = {
	ADD_POD: 'add_pod',
	REMOVE_POD: 'remove_pod',
	SET_POD_AGE: 'set_pod_age',
	SET_POD_RESTARTS: 'set_pod_restarts',
	SET_POD_STATUS: 'set_pod_status'
};

// Sites Action Types

export const SITES_ACTION_TYPES = {
	APPEND_NETWORK_LATENCY_TICK: 'append_network_latency_tick',
	APPEND_PORT_UTILIZATION_TICK: 'append_port_utilization_tick',
	DISCHARGE_UPS: 'discharge_ups',
	RECHARGE_UPS: 'recharge_ups',
	SET_ACTIVE: 'set_active',
	SET_DEGRADING_LATENCY: 'set_degrading_latency',
	SET_DEGRADING_POWER: 'set_degrading_power',
	SET_RECEIVING_WORKLOAD: 'set_receiving_workload',
	SET_TERMINATING_WORKLOAD: 'set_terminating_workload',
	SET_UPS_CHARGE: 'set_ups_charge',
	SET_UPS_STATUS: 'set_ups_status'
};

// Telemetry Stream Action Types

export const TELEMETRY_STREAM_ACTION_TYPES = {
	APPEND_TELEMETRY_DATA: 'append_telemetry_data',
	RESET_TELEMETRY_STREAM: 'reset_telemetry_stream'
};
