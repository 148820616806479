import * as React from 'react';
import classnames from 'classnames';
import { ICollapsibleContainerProps, ICollapsibleContainerState } from '../interfaces/ICollapsibleContainer';

class CollapsibleContainer extends React.Component<ICollapsibleContainerProps, ICollapsibleContainerState> {
	constructor(props) {
		super(props);

		this.state = {
			open: true
		};
	}

	onHeaderClick = () => {
		const { open } = this.state;

		this.setState({
			open: !open
		});
	}

	render() {
		const { title } = this.props;
		const { open } = this.state;
		const componentClassNames = classnames('collapsible-container', { open });

		return (
			<div className={ componentClassNames }>
				<div
					className='header'
					onClick={ this.onHeaderClick }>
					<i className={ open ? 'fas fa-angle-down' : 'fas fa-angle-right' } />
					{ title }
				</div>

				{ open ? this.props.children : null }
			</div>
		);
	}
}

export default CollapsibleContainer;
