import {
	IDeploymentsState,
	IEmulatorState,
	IMapboxState,
	IPodsState,
	ISitesState,
	ITelemetryStreamState
} from './types';

// Deployments State

export const deploymentsState: IDeploymentsState = {
	deployments: [
		{
			age: '5 days',
			image: 'vaporio/vran',
			labels: [ 'Latency', 'Power', 'Critical' ],
			name: 'vran',
			pods: [
				'vran-pod-1'
				// 'vran-pod-2',
				// 'vran-pod-3'
			],
			slug: 'vran'
		},
		{
			age: '3 days',
			image: 'vaporio/computer-vision',
			labels: [ 'Power', 'Latency', 'Non-Critical' ],
			name: 'computer-vision',
			pods: [
				'computer-vision-pod-1'
				// 'computer-vision-pod-2',
				// 'computer-vision-pod-3',
				// 'computer-vision-pod-4'
			],
			slug: 'computer-vision'
		}
	]
};

// Emulator State

export const emulatorState: IEmulatorState = {
	emulatingGrid: false
};

// Mapbox State

export const mapboxState: IMapboxState = {
	mapRef: undefined
};

// Pods State

export const podsState: IPodsState = {
	pods: [
		// {
		// 	age: 432000,
		// 	name: 'vran-32f52gcd2-f234',
		// 	restarts: 0,
		// 	site: 'KE1-ATL',
		// 	slug: 'vran-pod-1',
		// 	status: 'Running'
		// },
		// {
		// 	age: 864000,
		// 	name: 'vran-14e28sef3-k927',
		// 	restarts: 0,
		// 	site: 'KE2-ATL',
		// 	slug: 'vran-pod-2',
		// 	status: 'Running'
		// },
		{
			age: 432000,
			name: 'vran-32f52gcd2-f234',
			restarts: 0,
			site: 'KE1-LAS',
			slug: 'vran-pod-1',
			status: 'Running'
		},
		// {
		// 	age: 864000,
		// 	name: 'vran-14e28sef3-k927',
		// 	restarts: 0,
		// 	site: 'KE2-LAS',
		// 	slug: 'vran-pod-2',
		// 	status: 'Running'
		// },
		// {
		// 	age: 432000,
		// 	name: 'vran-75d19ags1-a432',
		// 	restarts: 0,
		// 	site: 'KE3-ATL',
		// 	slug: 'vran-pod-3',
		// 	status: 'Running'
		// },
		// {
		// 	age: 13080,
		// 	name: 'computer-vision-85c45b64cb-r6223',
		// 	restarts: 0,
		// 	site: 'KE1-ATL',
		// 	slug: 'computer-vision-pod-1',
		// 	status: 'Running'
		// },
		// {
		// 	age: 26160,
		// 	name: 'computer-vision-85c45b64ac-r3212',
		// 	restarts: 0,
		// 	site: 'KE2-ATL',
		// 	slug: 'computer-vision-pod-2',
		// 	status: 'Running'
		// },
		{
			age: 13080,
			name: 'computer-vision-85c45b64cb-r6223',
			restarts: 0,
			site: 'KE1-LAS',
			slug: 'computer-vision-pod-1',
			status: 'Running'
		}
		// {
		// 	age: 26160,
		// 	name: 'computer-vision-85c45b64ac-r3212',
		// 	restarts: 0,
		// 	site: 'KE2-LAS',
		// 	slug: 'computer-vision-pod-2',
		// 	status: 'Running'
		// },
		// {
		// 	age: 13080,
		// 	name: 'computer-vision-85c45b64cb-r6223',
		// 	restarts: 0,
		// 	site: 'KE3-ATL',
		// 	slug: 'computer-vision-pod-3',
		// 	status: 'Running'
		// },
		// {
		// 	age: 26160,
		// 	name: 'computer-vision-85c45b64fm-r1993',
		// 	restarts: 0,
		// 	site: 'KE4-ATL',
		// 	slug: 'computer-vision-pod-4',
		// 	status: 'Running'
		// }
	]
};

// Sites State

export const sitesState: ISitesState = {
	sites: [
		// {
		// 	active: true,
		//	degradingLatency: false,
		// 	degradingPower: false,
		// 	latitude: 34.5751,
		// 	longitude: -83.8109,
		// 	name: 'Woodwin',
		// 	networkLatency: {
		// 		entries: [
		// 			{
		// 				label: '00:00:00',
		// 				latency: 60
		// 			}
		// 		],
		// 		title: 'Network Latency - KE1-ATL',
		// 		unit: 'μs'
		// 	},
		// 	portUtilization: {
		// 		entries: [],
		// 		title: 'Port Utilization - KE1-ATL',
		// 		unit: '%'
		// 	},
		// 	priority: 85,
		// 	receivingWorkload: false,
		// 	runningWorkload: true,
		// 	slug: 'KE1-ATL',
		// 	terminatingWorkload: false,
		// 	upsCharge: 100,
		// 	upsStatus: 'Battery Charged'
		// },
		// {
		// 	active: true,
		//	degradingLatency: false,
		// 	degradingPower: false,
		// 	latitude: 33.754,
		// 	longitude: -84.3989,
		// 	name: 'Mitchell',
		// 	networkLatency: {
		// 		entries: [
		// 			{
		// 				label: '00:00:00',
		// 				latency: 55
		// 			}
		// 		],
		// 		title: 'Network Latency - KE2-ATL',
		// 		unit: 'μs'
		// 	},
		// 	portUtilization: {
		// 		entries: [],
		// 		title: 'Port Utilization - KE2-ATL',
		// 		unit: '%'
		// 	},
		// 	priority: 50,
		// 	receivingWorkload: false,
		// 	runningWorkload: true,
		// 	slug: 'KE2-ATL',
		// 	terminatingWorkload: false,
		// 	upsCharge: 100,
		// 	upsStatus: 'Battery Charged'
		// },
		{
			active: true,
			degradingLatency: false,
			degradingPower: false,
			latitude: 36.1586,
			longitude: -115.225,
			name: 'South Jones',
			networkLatency: {
				entries: [
					{
						label: '00:00:00',
						latency: 60
					}
				],
				title: 'Network Latency - KE1-LAS',
				unit: 'μs'
			},
			portUtilization: {
				entries: [],
				title: 'Port Utilization - KE1-LAS',
				unit: '%'
			},
			priority: 85,
			receivingWorkload: false,
			runningWorkload: true,
			slug: 'KE1-LAS',
			terminatingWorkload: false,
			upsCharge: 100,
			upsStatus: 'Battery Charged'
		},
		{
			active: true,
			degradingLatency: false,
			degradingPower: false,
			latitude: 36.1291,
			longitude: -115.145,
			name: 'Swenson',
			networkLatency: {
				entries: [
					{
						label: '00:00:00',
						latency: 55
					}
				],
				title: 'Network Latency - KE2-LAS',
				unit: 'μs'
			},
			portUtilization: {
				entries: [],
				title: 'Port Utilization - KE2-LAS',
				unit: '%'
			},
			priority: 50,
			receivingWorkload: false,
			runningWorkload: true,
			slug: 'KE2-LAS',
			terminatingWorkload: false,
			upsCharge: 100,
			upsStatus: 'Battery Charged'
		}
		// {
		// 	active: true,
		//	degradingLatency: false,
		// 	degradingPower: false,
		// 	latitude: 34.78241,
		// 	longitude: -84.9868,
		// 	name: 'Windy Hill',
		// 	networkLatency: {
		// 		entries: [
		// 			{
		// 				label: '00:00:00',
		// 				latency: 65
		// 			}
		// 		],
		// 		title: 'Network Latency - KE3-ATL',
		// 		unit: 'μs'
		// 	},
		// 	portUtilization: {
		// 		entries: [],
		// 		title: 'Port Utilization - KE3-ATL',
		// 		unit: '%'
		// 	},
		// 	priority: 75,
		// 	receivingWorkload: false,
		// 	runningWorkload: true,
		// 	slug: 'KE3-ATL',
		// 	terminatingWorkload: false,
		// 	upsCharge: 100,
		// 	upsStatus: 'Battery Charged'
		// },
		// {
		// 	active: true,
		//	degradingLatency: false,
		// 	degradingPower: false,
		// 	latitude: 33.945,
		// 	longitude: -84.1191,
		// 	name: 'Franklin',
		// 	networkLatency: {
		// 		entries: [
		// 			{
		// 				label: '00:00:00',
		// 				latency: 60
		// 			}
		// 		],
		// 		title: 'Network Latency - KE4-ATL',
		// 		unit: 'μs'
		// 	},
		// 	portUtilization: {
		// 		entries: [],
		// 		title: 'Port Utilization - KE4-ATL',
		// 		unit: '%'
		// 	},
		// 	priority: 95,
		// 	receivingWorkload: false,
		// 	runningWorkload: true,
		// 	slug: 'KE4-ATL',
		// 	terminatingWorkload: false,
		// 	upsCharge: 100,
		// 	upsStatus: 'Battery Charged'
		// },
		// {
		// 	active: true,
		//	degradingLatency: false,
		// 	degradingPower: false,
		// 	latitude: 40.4374,
		// 	longitude: -79.9441,
		// 	name: 'East Liberty',
		// 	networkLatency: {
		// 		entries: [
		// 			{
		// 				label: '00:00:00',
		// 				latency: 8
		// 			}
		// 		],
		// 		title: 'Network Latency - KE3-PIT',
		// 		unit: 'ms'
		// 	},
		// 	portUtilization: {
		// 		entries: [],
		// 		title: 'Port Utilization - KE3-PIT',
		// 		unit: '%'
		// 	},
		// 	priority: 90,
		// 	receivingWorkload: false,
		// 	runningWorkload: false,
		// 	slug: 'KE3-PIT',
		// 	terminatingWorkload: false,
		// 	upsCharge: 100,
		// 	upsStatus: 'Battery Charged'
		// }
	]
};

export const telemetryStreamState: ITelemetryStreamState = {
	telemetryStream: []
};
