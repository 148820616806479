import * as React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';
import { ISiteMarkerProps } from '../interfaces/ISiteMarker';
import { MAPBOX } from '../constants/MAPBOX';
import { Marker, Popup } from 'react-mapbox-gl';

const marker = require('../../images/svg/pods-map-marker.svg');

const mapStateToProps = (state) => {
	return {
		pods: state.podsState.pods
	};
};

class SiteMarker extends React.Component<ISiteMarkerProps, { displayTooltip: boolean }> {
	constructor(props) {
		super(props);

		this.state = {
			displayTooltip: false
		};
	}

	onMouseEnter = () => {
		this.setState({
			displayTooltip: true
		});
	}

	onMouseLeave = () => {
		this.setState({
			displayTooltip: false
		});
	}

	renderMarkerImage = () => {
		const { alt, translucent = false } = this.props;

		if (translucent) {
			return (
				<img
					className='translucent'
					src={ marker }
					alt={ alt }
					height={ 35 }
					width={ 35 } />
			);
		}

		return (
			<img
				src={ marker }
				alt={ alt }
				height={ 35 }
				width={ 35 } />
		);
	}

	renderPopup = () => {
		const {
			coordinates,
			pulseGreen,
			pulseRed,
			site,
			pods
		} = this.props;
		const { displayTooltip } = this.state;

		if (displayTooltip || pulseGreen || pulseRed) {
			const title = `${site} Workloads`;
			const sitePods = pods.filter((pod) => pod.site === site);

			return (
				<Popup
					anchor={ 'bottom' }
					className='popup'
					coordinates={ coordinates }
					offset={ MAPBOX.OFFSETS.TOOLTIP }>
					<div className='icon-tooltip'>
						<div className='title'>{ title }</div>
						{ this.renderSitePods(sitePods) }
					</div>
				</Popup>
			);
		}

		return null;
	}

	renderSitePods = (sitePods) => {
		if (sitePods && sitePods.length < 1) {
			return <div className='workload'>No Active Workload</div>;
		}

		return sitePods.map((sitePod) => {
			const {
				name,
				slug,
				status
			} = sitePod;
			const classNames = classnames('workload', {
				running: status === 'Running',
				starting: status === 'Starting',
				terminated: status === 'Terminated',
				terminating: status === 'Terminating'
			});

			return (
				<div
					key={ slug}
					className={ classNames }>
					{ name }
				</div>
			);
		});
	}

	render() {
		const {
			coordinates,
			onClick,
			pulseGreen,
			pulseRed
		} = this.props;
		const classNames = classnames('marker', {
			'pulse-green': pulseGreen,
			'pulse-red': pulseRed
		});

		return (
			<ErrorBoundary>
				<Marker
					className={ classNames }
					coordinates={ coordinates }
					offset={ MAPBOX.OFFSETS.MARKER }
					onClick={ onClick }
					onMouseEnter={ this.onMouseEnter }
					onMouseLeave={ this.onMouseLeave }>
					{ this.renderMarkerImage() }
				</Marker>

				{ this.renderPopup() }
			</ErrorBoundary>
		);
	}
}

export default connect(mapStateToProps, null)(SiteMarker);
