import * as React from 'react';
import classnames from 'classnames';
import { IPodsTableProps, IPodsTableState } from '../interfaces/IPodsTable';
import StyleTag from './StyleTag';
import { Spinner, Table } from 'react-bootstrap';

class PodsTable extends React.Component<IPodsTableProps, IPodsTableState> {
	formatAge = (days: number, hours: number, minutes: number, seconds: number) => {
		if (days > 0) {
			return `${days} days`;
		}
		else if (hours > 0) {
			return `${hours}h${minutes}m${seconds}s`;
		}
		else if (minutes > 0) {
			return `${minutes}m${seconds}s`;
		}

		return `${seconds}s`;
	}

	renderPods() {
		const { pods } = this.props;

		return pods.map((pod) => {
			const {
				age,
				name,
				restarts,
				site,
				slug,
				status
			} = pod;
			const days = Math.floor(age / 86400);
			const hours = Math.floor(age % 86400 / 3600);
			const minutes = Math.floor(age % 86400 % 3600 / 60);
			const seconds = Math.floor(age % 86400 % 3600 % 60);
			const formattedAge = this.formatAge(days, hours, minutes, seconds);
			const rowClassNames = classnames('table-row', {
				running: status === 'Running',
				starting: status === 'Starting',
				terminated: status === 'Terminated',
				terminating: status === 'Terminating'
			});

			return (
				<tr className={ rowClassNames } key={ slug }>
					<td className='name'>
						{ this.renderPodsStatusIcon(status) }
						{ name }
					</td>
					<td>{ site }</td>
					<td>
						<StyleTag
							key={ slug }
							tagMessage={ status } />
					</td>
					<td>{ restarts }</td>
					<td>{ formattedAge }</td>
				</tr>
			);
		});
	}

	renderPodsStatusIcon(status: string) {
		if (status === 'Starting' || status === 'Terminating') {
			return (
				<Spinner animation={ 'border' } />
			);
		}

		if (status === 'Terminated') {
			return (
				<i className='fas fa-times-circle danger' />
			);
		}

		return (
			<i className='fas fa-check-circle success' />
		);
	}

	renderTableBody = () => {
		return (
			<tbody>
				{ this.renderPods() }
			</tbody>
		);
	}

	renderTableHeader = () => {
		return (
			<thead className='table-header'>
				<tr>
					<td>{ 'Name' }</td>
					<td>{ 'Node' }</td>
					<td>{ 'Status' }</td>
					<td>{ 'Restarts' }</td>
					<td>{ 'Age' }</td>
				</tr>
			</thead>
		);
	}

	render() {
		return (
			<Table className='pods-table' responsive={ true }>
				{ this.renderTableHeader() }
				{ this.renderTableBody() }
			</Table>
		);
	}
}

export default PodsTable;
