import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IAppProps } from '../interfaces/IApp';
import GridDashboard from './GridDashboard';
import GridMap from './GridMap';
import { startEmulator } from '../redux/actions';

const mapDispatchToProps = (dispatch) => {
	return {
		startEmulator: bindActionCreators(startEmulator, dispatch)
	};
};

class App extends React.Component<IAppProps, {}> {
	componentDidMount() {
		const { startEmulator } = this.props;

		startEmulator();
	}

	render() {
		return (
			<div id='app-container'>
				<GridMap />
				<GridDashboard />
			</div>
		);
	}
}

export default connect(null, mapDispatchToProps)(App);
