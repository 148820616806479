import * as React from 'react';
import classnames from 'classnames';
import { IToggleProps } from '../interfaces/IToggle';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class Toggle extends React.Component<IToggleProps, {}> {
	renderToggle() {
		const {
			checked,
			disabled,
			onToggleClick
		} = this.props;
		const sliderClassNames = classnames('slider round', {
			disabled
		});

		return (
			<div className='toggle'>
				<label className='switch'>
					<input type='checkbox'
						checked={ checked }
						onChange={ disabled ? () => undefined : onToggleClick } />
					<span className={ sliderClassNames } />
				</label>
			</div>
		);
	}

	renderToggleTooltip = (tooltipMessage: string) => {
		return (
			<Tooltip id='toggle-tooltip'>
				<span>{ tooltipMessage }</span>
			</Tooltip>
		);
	}

	render() {
		const { tooltipMessage } = this.props;

		if (tooltipMessage) {
			return (
				<OverlayTrigger
					delay={ 200 }
					overlay={ this.renderToggleTooltip(tooltipMessage) }
					placement='top-start'>
					{ this.renderToggle() }
				</OverlayTrigger>
			);
		}

		return this.renderToggle();
	}
}

export default Toggle;
