export const DEMO = {
	EVENT_TIMELINES: {
		DEGRADATION_OF_EMPTY_SITE_TO_INACTIVE: 10000,
		DEGRADATION_OF_SITE_TO_INSTANTIATION_OF_PODS: 4000,
		INSTANTIATION_OF_PODS_TO_MIGRATION_OF_WORKLOADS_RUNNING: 6000,
		MIGRATION_OF_WORKLOADS_RUNNING_TO_MIGRATION_OF_WORKLOADS_TERMINATING: 2000,
		MIGRATION_OF_WORKLOADS_TERMINATING_TO_TERMINATION_OF_PODS: 3000,
		TERMINATION_OF_PODS_TO_REMOVAL_OF_PODS: 2500
	},
	EVENT_TRANSITIONS: {
		INITIAL_VIEW_TO_DEGRADED_SITE_SPEED: 0.4,
		WORKLOAD_MIGRATION_DURATION: 4000
	},
	MAP_BOUNDS_PADDING: {
		bottom: 200,
		left: 150,
		right: 150,
		top: 150
	},
	TICK_DURATION: 2000,
	ZOOM_LEVELS: {
		DEGRADED_SITE: 7.5
	}
};
