import * as React from 'react';
import { bindActionCreators } from 'redux';
import {
	Col,
	Container,
	Row
} from 'react-bootstrap';
import CollapsibleContainer from './CollapsibleContainer';
import { connect } from 'react-redux';
import { IGridDashboardProps, IGridDashboardState } from '../interfaces/IGridDashboard';
import DeploymentsTable from './DeploymentsTable';
import ErrorBoundary from './ErrorBoundary';
import MaintenanceControl from './MaintenanceControl';
import NetworkLatencyChart from './NetworkLatencyChart';
import PodsTable from './PodsTable';
import PortUtilizationChart from './PortUtilizationChart';
import { setDegradingLatency, setDegradingPower } from '../redux/actions';
import UpsChargeWidget from './UpsChargeWidget';
import UpsStatusWidget from './UpsStatusWidget';

const mapDispatchToProps = (dispatch) => {
	return {
		setDegradingLatency: bindActionCreators(setDegradingLatency, dispatch),
		setDegradingPower: bindActionCreators(setDegradingPower, dispatch)
	};
};

const mapStateToProps = (state) => {
	return {
		deployments: state.deploymentsState.deployments,
		pods: state.podsState.pods,
		sites: state.sitesState.sites
	};
};

const gridLogo = require('../../images/svg/zg_logo.svg');

class GridDashboard extends React.Component<IGridDashboardProps, IGridDashboardState> {
	renderDeploymentsTable = () => {
		const { deployments } = this.props;
		const title = `Deployments (${deployments.length})`;

		if (deployments && deployments.length > 0) {
			return (
				<CollapsibleContainer title={ title }>
					<div className='deployments'>
						<DeploymentsTable deployments={ deployments } />
					</div>
				</CollapsibleContainer>
			);
		}

		return null;
	}

	renderPodsTable = () => {
		const { pods } = this.props;
		const title = `Workloads / Pods (${pods.length})`;

		return (
			<CollapsibleContainer title={ title }>
				<div className='pods'>
					<PodsTable pods={ pods } />
				</div>
			</CollapsibleContainer>
		);
	}

	renderDashboard = () => {
		return (
			<ErrorBoundary>
				<Col>
					{ this.renderDeploymentsTable() }
					{ this.renderPodsTable() }
					{ this.renderSitesTelemetry() }
				</Col>
			</ErrorBoundary>
		);
	}

	renderSitesTelemetry = () => {
		const { sites } = this.props;

		return sites.map((site) => {
			const {
				name,
				networkLatency,
				portUtilization,
				slug,
				upsCharge,
				upsStatus
			} = site;

			return (
				<CollapsibleContainer key={ slug } title={ `${slug} (${name})` }>
					<Row>
						<Col
							sm={ 12 }
							md={ 6 }
							lg={ 6 }>
							<PortUtilizationChart chartData={ portUtilization } site={ slug } />
							<UpsStatusWidget site={ slug } upsStatus={ upsStatus } />
						</Col>

						<Col
							sm={ 12 }
							md={ 6 }
							lg={ 6 }>
							<NetworkLatencyChart chartData={ networkLatency } site={ slug } />
							<UpsChargeWidget site={ slug } upsCharge={ upsCharge } />
						</Col>
					</Row>
				</CollapsibleContainer>
			);
		});
	}

	render() {
		const { setDegradingLatency, setDegradingPower, sites } = this.props;

		return (
			<div id='grid-dashboard'>
				<div id='dashboard-header'>
					<div id='left'>
						<img
							className='logo'
							src={ gridLogo } />
					</div>

					<div id='right'>
						{ 'Workload Orchestration'}
					</div>
				</div>

				<div id='dashboard'>
					<Container fluid={ true }>
						<Row>
							{ this.renderDashboard() }
						</Row>
					</Container>
				</div>

				<MaintenanceControl
					setDegradingLatency={ setDegradingLatency }
					setDegradingPower={ setDegradingPower }
					sites={ sites } />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GridDashboard);
