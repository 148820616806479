export const MAPBOX = {
	ACCESS_TOKEN: 'pk.eyJ1IjoidmFwb3JpbyIsImEiOiJjamt5NmczNXkwZzhtM3Fxanl2MHFycmtsIn0.ThSiP4DrhMDQ29ItdI6-ng',

	DEFAULT_OPTIONS: {
		DRAG_ROTATE: false,
		INJECT_CSS: false,
		RENDER_WORLD_COPIES: false,

		COMPONENT: {
			// CENTER: [ -84.35, 33.9 ],
			CENTER: [ -115.18, 36.13 ],
			CONTAINER_STYLE: {
				height: '100%',
				width: '100%'
			},
			MAX_BOUNDS: [
				[ -169.756844, -70.172648 ],
				[ 180, 84.506943 ]
			],
			// ZOOM: 4
			ZOOM: 10
		},

		CLUSTER: {
			MAX_ZOOM: 5,
			ZOOM_ON_CLICK: true,
			ZOOM_ON_CLICK_PADDING: 180
		},

		MARKER: {
			ZOOM_LEVEL_ON_CLICK: 10
		}
	},

	OFFSETS: {
		CLUSTER_TOOLTIP: [ 0, -45 ],
		MARKER: {
			bottom: [ 0, 15 ]
		},
		TOOLTIP: [ 0, -35 ]
	},

	STYLE: {
		NAME: 'dark',
		URL: 'mapbox://styles/vaporio/cjky6ka6h2f7l2rlg6bmdx469'
	}
};
