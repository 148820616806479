import * as React from 'react';
import classnames from 'classnames';
import { IMapButtonsProps } from '../interfaces/IMapButtons';
import { ZoomControl } from 'react-mapbox-gl';

class MapButtons extends React.Component<IMapButtonsProps, {}> {
	render() {
		const { telemetryConsole } = this.props;
		const classNames = classnames('map-buttons', {
			'console-open': telemetryConsole
		});

		return (
			<div className={ classNames }>
				<ZoomControl
					className='zoom-control'
					position='bottom-left' />
			</div>
		);
	}
}

export default MapButtons;
