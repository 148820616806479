import * as React from 'react';
import classnames from 'classnames';
import { IStyleTagProps } from '../interfaces/IStyleTag';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { STYLE_TAG_COLORS } from '../constants/STYLE_TAG';

class StyleTag extends React.Component<IStyleTagProps, {}> {
	renderStyleTag = () => {
		const {
			tagMessage,
			tooltipMessage = tagMessage
		} = this.props;
		const styleString = STYLE_TAG_COLORS[ tooltipMessage.toLowerCase() ];
		const classNamesObj = {};
		classNamesObj[ styleString ] = true;
		const classNames = classnames('style-tag', classNamesObj);

		return (
			<div className={ classNames }>
				{ tagMessage }
			</div>
		);
	}

	renderStyleTagTooltip = (tooltipMessage: string) => {
		return (
			<Tooltip id={ tooltipMessage }>
				<span>{ tooltipMessage }</span>
			</Tooltip>
		);
	}

	render() {
		const { tooltipMessage } = this.props;

		if (tooltipMessage) {
			return (
				<OverlayTrigger
					delay={ 200 }
					overlay={ this.renderStyleTagTooltip(tooltipMessage) }
					placement='right'>
					{ this.renderStyleTag() }
				</OverlayTrigger>
			);
		}

		return this.renderStyleTag();
	}
}

export default StyleTag;
