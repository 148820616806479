import '../css/index.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import KineticGrid from './components/KineticGrid';
import { Provider } from 'react-redux';
import store from './redux/store';

ReactDOM.render((
	<Provider store={ store }>
		<KineticGrid />
	</Provider>
), document.getElementById('root-container'));
