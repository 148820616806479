import { IAppState, IDeployment, IPod, ISite } from './types';

export const getEmulatingGrid = (state: IAppState): boolean => {
	const { emulatingGrid } = state.emulatorState;

	return emulatingGrid;
};

export const getMapRef = (state: IAppState): any => {
	const { mapRef } = state.mapboxState;

	return mapRef;
};

export const getSites = (state: IAppState): Array<ISite> => {
	const { sites } = state.sitesState;

	return sites;
};

export const getPods = (state: IAppState): Array<IPod> => {
	const { pods } = state.podsState;

	return pods;
};

export const getDeployments = (state: IAppState): Array<IDeployment> => {
	const { deployments } = state.deploymentsState;

	return deployments;
};
