import * as React from 'react';
import { IGridMapProps, IGridMapState } from '../interfaces/IGridMap';
import MapBox from './MapBox';

class GridMap extends React.Component<IGridMapProps, IGridMapState> {
	constructor(props) {
		super(props);

		this.state = {
			mapPosition: 'right'
		};
	}

	onLayoutButtonClick = () => {
		const { mapPosition } = this.state;

		this.setState({
			mapPosition: (mapPosition === 'right') ? 'left' : 'right'
		});
	}

	render() {
		const { mapPosition } = this.state;

		return (
			<div id='grid-map' className={ mapPosition }>
				<div
					className='layout-button'
					onClick={ this.onLayoutButtonClick }>
					<i className='fas fa-exchange-alt' />
				</div>

				<MapBox />
			</div>
		);
	}
}

export default GridMap;
