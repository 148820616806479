import * as React from 'react';
import { IDeploymentsTableProps, IDeploymentsTableState } from '../interfaces/IDeploymentsTable';
import StyleTag from './StyleTag';
import { Table } from 'react-bootstrap';

class DeploymentsTable extends React.Component<IDeploymentsTableProps, IDeploymentsTableState> {
	renderDeployments() {
		const { deployments } = this.props;

		return deployments.map((deployment) => {
			const {
				age,
				image,
				labels,
				name,
				pods,
				slug
			} = deployment;

			return (
				<tr className='table-row' key={ slug }>
					<td className='name'>
						{ this.renderDeploymentStatusIcon() }
						{ name }
					</td>
					<td>{ this.renderLabels(labels) }</td>
					<td>{ pods.length }</td>
					<td>{ age }</td>
					<td>{ image }</td>
				</tr>
			);
		});
	}

	renderDeploymentStatusIcon() {
		return (
			<i className='fas fa-check-circle success' />
		);
	}

	renderLabels(labels: Array<string>) {
		return labels.map((label, index) => {
			return (
				<StyleTag
					key={ index }
					tagMessage={ label } />
			);
		});
	}

	renderTableBody = () => {
		return (
			<tbody>
				{ this.renderDeployments() }
			</tbody>
		);
	}

	renderTableHeader = () => {
		return (
			<thead className='table-header'>
				<tr>
					<td>{ 'Name' }</td>
					<td>{ 'Optimize For' }</td>
					<td>{ 'Pods' }</td>
					<td>{ 'Age' }</td>
					<td>{ 'Images' }</td>
				</tr>
			</thead>
		);
	}

	render() {
		return (
			<Table className='deployments-table' responsive={ true }>
				{ this.renderTableHeader() }
				{ this.renderTableBody() }
			</Table>
		);
	}
}

export default DeploymentsTable;
