import * as React from 'react';
import ErrorBoundary from './ErrorBoundary';
import { IClusterMarkerProps } from '../interfaces/IClusterMarker';
import { Marker } from 'react-mapbox-gl';

const marker = require('../../images/svg/multi-pods-map-marker.svg');

class ClusterMarker extends React.PureComponent<IClusterMarkerProps, {}> {
	render() {
		const { coordinates, pointCount } = this.props;

		return (
			<Marker
				className='cluster-marker'
				coordinates={ coordinates }>
					<ErrorBoundary>
						<img
							src={ marker }
							alt={ 'alt' }
							height={ 35 }
							width={ 35 } />

						<div className='point-count'>{ pointCount }</div>
					</ErrorBoundary>
			</Marker>
		);
	}
}

export default ClusterMarker;
