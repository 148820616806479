import * as React from 'react';
import ErrorBoundary from './ErrorBoundary';
import { IPortUtilizationChartProps, IPortUtilizationChartState } from '../interfaces/IPortUtilizationChart';
import LineGraph from './LineGraph';

class PortUtilizationChart extends React.PureComponent<IPortUtilizationChartProps, IPortUtilizationChartState> {
	renderPortUtilizationChart = () => {
		const { chartData, site } = this.props;

		return (
			<ErrorBoundary>
				<div className='header'>
					<span>{ `${site} - Port Utilization` }</span>
				</div>

				<LineGraph
					data={ chartData }
					threshold={ 50 }
					yDomain={ [ 0, 100 ] } />
			</ErrorBoundary>
		);
	}

	render() {
		return (
			<div className='chart'>
				{ this.renderPortUtilizationChart() }
			</div>
		);
	}
}

export default PortUtilizationChart;
