import * as React from 'react';
import { bindActionCreators } from 'redux';
import ClusterMarker from './ClusterMarker';
import { connect } from 'react-redux';
import { IMapBoxProps, IMapBoxState } from '../interfaces/IMapBox';
import { MAPBOX } from '../constants/MAPBOX';
import MapButtons from './MapButtons';
import ReactMapboxGl, { Cluster } from 'react-mapbox-gl';
import { setMapRef } from '../redux/actions';
import SiteMarker from './SiteMarker';
import TelemetryConsole from './TelemetryConsole';

const mapDispatchToProps = (dispatch) => {
	return {
		setMapRef: bindActionCreators(setMapRef, dispatch)
	};
};

const mapStateToProps = (state) => {
	return {
		sites: state.sitesState.sites,
		telemetryStream: state.telemetryStreamState.telemetryStream
	};
};

const {
	ACCESS_TOKEN,
	DEFAULT_OPTIONS
} = MAPBOX;
const {
	COMPONENT,
	RENDER_WORLD_COPIES,
	DRAG_ROTATE
} = DEFAULT_OPTIONS;
const {
	CENTER,
	ZOOM
} = COMPONENT;
const Map = ReactMapboxGl({
	accessToken: ACCESS_TOKEN,
	dragRotate: DRAG_ROTATE,
	renderWorldCopies: RENDER_WORLD_COPIES
});

class MapBox extends React.Component<IMapBoxProps, IMapBoxState> {
	private map = null;

	constructor(props) {
		super(props);

		this.state = {
			center: [ CENTER[ 0 ], CENTER[ 1 ] ],
			isConsoleOpen: true,
			zoom: [ ZOOM ]
		};
	}

	mapFlyTo = (center, zoom) => {
		this.map.flyTo({
			center,
			zoom
		});
	}

	onConsoleButtonClick = () => {
		const { isConsoleOpen } = this.state;

		this.setState({
			isConsoleOpen: !isConsoleOpen
		});
	}

	onStyleLoad = (map) => {
		const { setMapRef } = this.props;

		this.map = map;

		setMapRef(this.map);
	}

	renderClusterMarker = (coordinates, pointCount) => {
		return (
			<ClusterMarker
				key={ coordinates }
				coordinates={ coordinates }
				pointCount={ pointCount } />
		);
	}

	renderSiteMarkers = (): any => {
		const { sites } = this.props;
		const { ZOOM_LEVEL_ON_CLICK } = MAPBOX.DEFAULT_OPTIONS.MARKER;

		if (!sites || sites.length < 1) {
			return null;
		}

		return sites.map((site) => {
			const {
				active,
				degradingLatency,
				degradingPower,
				name,
				longitude,
				latitude,
				receivingWorkload,
				slug
			} = site;
			const coordinates = [ longitude, latitude ];
			const offloadingWorkload = active && (degradingPower || degradingLatency);

			return (
				<SiteMarker
					key={ slug }
					alt={ name }
					coordinates={ coordinates }
					onClick={ this.mapFlyTo.bind(this, coordinates, ZOOM_LEVEL_ON_CLICK) }
					pulseGreen={ receivingWorkload }
					pulseRed={ offloadingWorkload }
					site={ slug }
					translucent={ !active } />
			);
		});
	}

	render() {
		const { telemetryStream } = this.props;
		const {
			center,
			isConsoleOpen,
			zoom
		} = this.state;
		const {
			DEFAULT_OPTIONS,
			STYLE
		} = MAPBOX;
		const {
			COMPONENT,
			CLUSTER
		} = DEFAULT_OPTIONS;
		const {
			CONTAINER_STYLE,
			MAX_BOUNDS
		} = COMPONENT;
		const {
			MAX_ZOOM,
			ZOOM_ON_CLICK,
			ZOOM_ON_CLICK_PADDING
		} = CLUSTER;
		const style = STYLE.URL;

		return (
			<div className='mapbox'>
				<Map
					onStyleLoad={ this.onStyleLoad }
					center={ center }
					containerStyle={ CONTAINER_STYLE }
					maxBounds={ MAX_BOUNDS }
					style={ style }
					zoom={ zoom }>
					<Cluster
						ClusterMarkerFactory={ this.renderClusterMarker }
						maxZoom={ MAX_ZOOM }
						zoomOnClick={ ZOOM_ON_CLICK }
						zoomOnClickPadding={ ZOOM_ON_CLICK_PADDING }>
						{ this.renderSiteMarkers() }
					</Cluster>

					<MapButtons telemetryConsole={ isConsoleOpen } />
				</Map>

				<TelemetryConsole
					onButtonClick={ this.onConsoleButtonClick }
					show={ isConsoleOpen }
					telemetryStream={ telemetryStream } />
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MapBox);
