import * as React from 'react';
import { IErrorBoundaryProps, IErrorBoundaryState } from '../interfaces/IErrorBoundary';

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			errorInfo: null
		};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error,
			errorInfo
		});
	}

	render() {
		const { error, errorInfo } = this.state;

		if (errorInfo) {
			return (
				<div id='error-boundary'>
					<div className='error'>{ 'Something went wrong.' }</div>

					<details style={ { whiteSpace: 'pre-wrap' } }>
						{ error && error.toString() }
					</details>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
