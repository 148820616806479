import * as React from 'react';
import ErrorBoundary from './ErrorBoundary';
import { INetworkLatencyChartProps, INetworkLatencyChartState } from '../interfaces/INetworkLatencyChart';
import LineGraph from './LineGraph';

class NetworkLatencyChart extends React.PureComponent<INetworkLatencyChartProps, INetworkLatencyChartState> {
	renderNetworkLatencyChart = () => {
		const { chartData, site } = this.props;

		return (
			<ErrorBoundary>
				<div className='header'>
					<span>{ `${site} - Radio Head Latency` }</span>
				</div>

				<LineGraph data={ chartData } />
			</ErrorBoundary>
		);
	}

	render() {
		return (
			<div className='chart'>
				{ this.renderNetworkLatencyChart() }
			</div>
		);
	}
}

export default NetworkLatencyChart;
