import * as React from 'react';
import {
	CartesianGrid,
	Line,
	LineChart,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import { CHARTS } from '../constants/CHARTS';
import ErrorBoundary from './ErrorBoundary';
import { ILineGraphProps } from '../interfaces/ILineGraph';

class LineGraph extends React.PureComponent<ILineGraphProps, {}> {
	getDataKeys = (data: any) => {
		const { entries } = data;
		const firstEntry = entries[ 0 ];
		const dataKeys = firstEntry && Object.keys(firstEntry).filter((entry) => {
			return entry !== 'label';
		});

		return dataKeys;
	}

	renderLines = (dataKeys) => {
		const { COLOR_CODES, LINE_CHART } = CHARTS;
		const { IS_ANIMATION_ACTIVE, LINE_DOT } = LINE_CHART;

		return dataKeys.map((dataKey, index) => {
			return (
				<Line
					key={ dataKey }
					type='monotone'
					dataKey={ dataKey }
					dot={ LINE_DOT }
					isAnimationActive={ IS_ANIMATION_ACTIVE }
					stroke={ COLOR_CODES[ index ] } />
			);
		});
	}

	render() {
		const {
			data,
			threshold,
			xDomain,
			yDomain
		} = this.props;
		const { entries, unit } = data;
		const dataKeys = this.getDataKeys(data);
		const { LINE_CHART } = CHARTS;
		const {
			GRAPH_MARGIN,
			MIN_TICK_GAP,
			STROKE_DASH_ARRAY,
			TOOLTIP,
			X_AXIS,
			Y_AXIS
		} = LINE_CHART;

		return (
			<ErrorBoundary>
				<ResponsiveContainer height='85%' width='100%'>
					<LineChart data={ entries } margin={ GRAPH_MARGIN }>
						<XAxis
							domain={ xDomain }
							angle={ X_AXIS.ANGLE }
							dataKey='label'
							dx={ X_AXIS.DX }
							textAnchor={ X_AXIS.TEXT_ANCHOR } />

						<YAxis
							domain={ yDomain }
							minTickGap={ MIN_TICK_GAP }
							unit={ unit && ` ${unit}` }
							width={ Y_AXIS.WIDTH } />

						<CartesianGrid strokeDasharray={ STROKE_DASH_ARRAY } />

						{ dataKeys && this.renderLines(dataKeys) }

						{ threshold && <ReferenceLine y={ threshold } stroke='red' strokeDasharray='3 3' /> }

						<Tooltip
							allowEscapeViewBox={ TOOLTIP.ALLOW_ESCAPE_VIEW_BOX }
							wrapperStyle={ TOOLTIP.WRAPPER_STYLE } />
					</LineChart>
				</ResponsiveContainer>

				{ !dataKeys && <div className='no-data-point'>No data points.</div> }
			</ErrorBoundary>
		);
	}
}

export default LineGraph;
