import { ITelemetry } from './types';

// Gets a random integer from the lower range to 1 lower than the upper
// ie. getRandomInt(0,3) will return a value from [ 0, 1, 2 ]
export function getRandomInt(lowerRange: number, upperRange: number): number {
	return Math.floor(Math.random() * Math.floor(upperRange - lowerRange)) + lowerRange;
}

export function smartPop(array: Array<any>, value: any): Array<any> {
	const poppedArray = array.filter((item) => item !== value);

	return poppedArray;
}

export function getUpdatedTelemetryStream(telemetryStream: Array<ITelemetry>, telemetry: ITelemetry) {
	const stream = JSON.parse(JSON.stringify(telemetryStream));

	if (stream.length > 30) {
		stream.shift();
	}

	stream.push(telemetry);

	return stream;
}

export function getPortUtilizationTick(value: number, probability: number, ascending: boolean): number {
	const step = getRandomInt(1, 4);
	const newValue = ascending ? value + step : value - step;

	if (getRandomInt(0, 101) / 100 > probability) {
		if (newValue < 1) {
			return 1;
		}
		else if (newValue > 100) {
			return 100;
		}

		return newValue;
	}

	return getPortUtilizationTick(newValue, probability, ascending);
}

export function getNetworkLatencyTick(value: number, unit: string, degrading: boolean): number {
	if (unit === 'μs') {
		const newValue = degrading ? (value + getRandomInt(10, 15)) : (value > 90 ? (value - getRandomInt(21, 32)) : (value + getRandomInt(-5, 6)));

		if (value > 1100) {
			return value - 99999;
		}

		if (newValue > 40 && newValue <= 240) {
			return newValue;
		}

		return 60;
	}
	else if (unit === 'ms') {
		const newValue = degrading ? (value + getRandomInt(6, 11) / 10) : (value > 20 ? (value - getRandomInt(11, 19) / 10) : (value + getRandomInt(-2, 3) / 10));

		if (value > 100) {
			return value - 10000;
		}

		if (newValue > 5 && newValue <= 30) {
			return newValue;
		}

		return 8;
	}

	return value;
}
