import {
	addPod,
	appendNetworkLatencyTick,
	appendPod,
	appendPortUtilizationTick,
	appendTelemetryData,
	dischargeUps,
	popPod,
	rechargeUps,
	removePod,
	resumeEmulator,
	setActive,
	setDegradingLatency,
	setDegradingPower,
	setPodAge,
	setPodStatus,
	setReceivingWorkLoad,
	setTerminatingWorkLoad,
	setUpsStatus
} from './actions';
import {
	all,
	delay,
	put,
	select,
	takeEvery,
	takeLatest
} from 'redux-saga/effects';
import { DEMO } from '../constants/DEMO';
import { EMULATOR_ACTION_TYPES, SITES_ACTION_TYPES } from './action_types';
import { ISite, ISitesAction } from './types';
import {
	getDeployments,
	getEmulatingGrid,
	getMapRef,
	getPods,
	getSites
} from './selectors';
import {
	getNetworkLatencyTick,
	getPortUtilizationTick,
	getRandomInt
} from './lib';

const moment = require('moment');

function* decayLogic(action: ISitesAction) {
	try {
		const { degrading, siteSlug: degradedSiteSlug } = action;

		const map = yield select(getMapRef);
		const sites: Array<ISite> = yield select(getSites);
		const degradedSite = sites.find(((site) => site.slug === degradedSiteSlug));
		const targetSite: ISite = sites.filter((site) => site.active && !site.degradingPower && !site.degradingLatency && !site.receivingWorkload).sort((a, b) => b.priority - a.priority)[ 0 ];

		if (!degrading || !targetSite) {
			return;
		}

		const targetSiteSlug: string = targetSite.slug;
		const pods = yield select(getPods);
		const degradedPods = pods.filter((pod) => pod.site === degradedSiteSlug);
		const podsToDeploy = degradedPods.map((pod) => {
			return {
				age: 0,
				name: `${pod.name.slice(0, -5)}${Math.random().toString(36).substr(2, 5)}`,
				restarts: 0,
				site: targetSiteSlug,
				slug: `${pod.slug}-migrated-to-${targetSiteSlug.toLowerCase()}`,
				status: 'Starting'
			};
		});
		const {
			EVENT_TIMELINES,
			EVENT_TRANSITIONS,
			MAP_BOUNDS_PADDING,
			ZOOM_LEVELS
		} = DEMO;
		const {
			DEGRADATION_OF_EMPTY_SITE_TO_INACTIVE,
			DEGRADATION_OF_SITE_TO_INSTANTIATION_OF_PODS,
			INSTANTIATION_OF_PODS_TO_MIGRATION_OF_WORKLOADS_RUNNING,
			MIGRATION_OF_WORKLOADS_RUNNING_TO_MIGRATION_OF_WORKLOADS_TERMINATING,
			MIGRATION_OF_WORKLOADS_TERMINATING_TO_TERMINATION_OF_PODS,
			TERMINATION_OF_PODS_TO_REMOVAL_OF_PODS
		} = EVENT_TIMELINES;
		const { INITIAL_VIEW_TO_DEGRADED_SITE_SPEED, WORKLOAD_MIGRATION_DURATION } = EVENT_TRANSITIONS;
		const { DEGRADED_SITE } = ZOOM_LEVELS;

		if (degradedSite.degradingPower) {
			yield put(setUpsStatus(degradedSiteSlug, 'Battery Engaged'));
		}

		if (degradedPods.length < 1) {
			map.flyTo({
				center: [ degradedSite.longitude, degradedSite.latitude ],
				essential: true,
				speed: INITIAL_VIEW_TO_DEGRADED_SITE_SPEED,
				zoom: DEGRADED_SITE
			});

			yield put(setTerminatingWorkLoad(degradedSiteSlug, true)); // Sets site status to terminating workload to disable power toggle

			yield delay(DEGRADATION_OF_EMPTY_SITE_TO_INACTIVE);

			yield put(setTerminatingWorkLoad(degradedSiteSlug, false)); // Enables the power toggle again
			yield put(setActive(degradedSiteSlug, false)); // Sets the site marker to transparent

			return;
		}

		map.fitBounds([
			[ degradedSite.longitude, degradedSite.latitude ],
			[ targetSite.longitude, targetSite.latitude ]
		], {
			animate: true,
			duration: WORKLOAD_MIGRATION_DURATION,
			essential: true,
			padding: MAP_BOUNDS_PADDING
		});

		yield put(setTerminatingWorkLoad(degradedSiteSlug, true)); // Sets degrading site's status to terminating workload

		yield delay(DEGRADATION_OF_SITE_TO_INSTANTIATION_OF_PODS);

		yield put(setReceivingWorkLoad(targetSiteSlug, true)); // Target site markers start pulsing green

		for (let i = 0; i < podsToDeploy.length; i++) {
			const pod = podsToDeploy[ i ];

			yield put(addPod(pod)); // Adds the new pods to pods table with 'Starting' status.
		}

		yield delay(INSTANTIATION_OF_PODS_TO_MIGRATION_OF_WORKLOADS_RUNNING);

		for (let i = 0; i < podsToDeploy.length; i++) {
			const { slug } = podsToDeploy[ i ];

			yield put(setPodStatus(slug, 'Running')); // Set 'Starting' pods' status to 'Running'.
		}

		yield delay(MIGRATION_OF_WORKLOADS_RUNNING_TO_MIGRATION_OF_WORKLOADS_TERMINATING);

		for (let i = 0; i < degradedPods.length; i++) {
			const { slug } = degradedPods[ i ];

			yield put(setPodStatus(slug, 'Terminating')); // Sets degrading pods' status to 'Terminating'.
		}

		yield delay(MIGRATION_OF_WORKLOADS_TERMINATING_TO_TERMINATION_OF_PODS);

		for (let i = 0; i < degradedPods.length; i++) {
			const { slug } = degradedPods[ i ];

			yield put(setPodStatus(slug, 'Terminated')); // Sets 'Terminating' degrading pod's status to 'Terminated'.
		}

		yield delay(TERMINATION_OF_PODS_TO_REMOVAL_OF_PODS);

		for (let i = 0; i < degradedPods.length; i++) {
			const { slug } = degradedPods[ i ];

			yield put(removePod(slug)); // Removes 'Terminated' pods, degraded pods won't be displayed in pods table anymore.
		}

		yield put(setTerminatingWorkLoad(degradedSiteSlug, false));
		yield put(setReceivingWorkLoad(targetSiteSlug, false)); // Target site markers stop pulsing green
		yield put(setActive(degradedSiteSlug, false)); // Sets degraded site's active flag to false, site marker becomes transparent.

		const deployments = yield select(getDeployments);

		for (let i = 0; i < deployments.length; i++) {
			const { pods: deploymentPods, slug: deploymentSlug } = deployments[ i ];

			for (let i = 0; i < deploymentPods.length; i++) {
				const deploymentPod = deploymentPods[ i ];

				if (degradedPods.some((pod) => pod.slug === deploymentPod)) {
					yield put(popPod(deploymentSlug, deploymentPod)); // Removes the degraded pod from its deployments.

					const { slug: podSlug } = podsToDeploy.find((pod) => pod.slug.includes(deploymentPod));

					yield put(appendPod(deploymentSlug, podSlug)); // Adds the new pod to its deployments.
				}
			}
		}
	} catch (e) {
		throw new Error(`Failed to initiate decay logic with error: ${e}`);
	}
}

function* gridEmulation() {
	try {
		const emulatingGrid = yield select(getEmulatingGrid);
		const sites = yield select(getSites);
		const pods = yield select(getPods);
		const { TICK_DURATION } = DEMO;
		const maxUpsCharge = 100;
		const minUpsCharge = 5;
		const maxLatency = 250;

		if (emulatingGrid) {
			yield delay(TICK_DURATION);

			for (let i = 0; i < sites.length; i++) {
				const {
					active,
					degradingLatency,
					degradingPower,
					networkLatency,
					receivingWorkload,
					runningWorkload,
					slug,
					terminatingWorkload,
					upsCharge,
					upsStatus
				} = sites[ i ];
				const { entries, unit } = networkLatency;
				const lastLatencyTick = entries.length > 0 ? entries[ entries.length - 1 ].latency : 20;
				const timestamp = moment().format('h:mm:ss');
				const ascending = Boolean(getRandomInt(0, 2));
				const ingressMean = terminatingWorkload ? 25 : receivingWorkload ? 55 : 40;
				const egressMean = terminatingWorkload ? 25 : receivingWorkload ? 55 : 35;
				const ingressTick = runningWorkload ? getPortUtilizationTick(ingressMean, .5, ascending) : 0;
				const egressTick = runningWorkload ? getPortUtilizationTick(egressMean, .5, ascending) : 0;
				const latencyTick = (active || !degradingLatency) ? getNetworkLatencyTick(lastLatencyTick, unit, degradingLatency) : 999999;
				const telemetry = {
					egress: egressTick,
					ingress: ingressTick,
					latency: latencyTick,
					location: slug,
					timestamp,
					upsCharge
				};

				yield all([
					put(appendNetworkLatencyTick(slug, timestamp, latencyTick)),
					put(appendPortUtilizationTick(slug, timestamp, egressTick, ingressTick)),
					put(appendTelemetryData(telemetry))
				]);

				if (!active && !degradingLatency && !degradingPower && (latencyTick < maxLatency)) {
					yield put(setActive(slug, true));
				}

				if (degradingPower) {
					if (upsCharge > minUpsCharge) {
						yield put(dischargeUps(slug, 4, minUpsCharge)); // Discharge battery when site is degraded and has some charge left
					}
					else {
						yield put(setDegradingPower(slug, false));
					}
				}
				else {
					if (upsCharge < maxUpsCharge) {
						yield put(setUpsStatus(slug, 'Battery Charging'));
						yield put(rechargeUps(slug, 4, maxUpsCharge)); // Recharge battery when site is not degraded and needs charging
					}

					if (upsStatus === 'Battery Charging' && upsCharge === maxUpsCharge) {
						yield put(setUpsStatus(slug, 'Battery Charged')); // Sets up status to charged after reaching max charge value
						yield put(setActive(slug, true));
					}
				}
			}

			for (let i = 0; i < pods.length; i++) {
				const {
					age,
					slug,
					status
				} = pods[ i ];
				const newAge = Number(age) + TICK_DURATION / 1000;

				if (status !== 'Terminated') {
					yield put(setPodAge(slug, newAge));
				}
			}

			yield put(resumeEmulator());
		}
	} catch (e) {
		throw new Error(`Failed to resume emulation with error: ${e}`);
	}
}

function* startEmulation() {
	try {
		yield put(resumeEmulator());
	} catch (e) {
		throw new Error(`Failed to start emulation with error: ${e}`);
	}
}

function* watchDecayLogic() {
	yield takeEvery(SITES_ACTION_TYPES.SET_DEGRADING_POWER, decayLogic);
	yield takeEvery(SITES_ACTION_TYPES.SET_DEGRADING_LATENCY, decayLogic);
}

function* watchStartEmulation() {
	yield takeLatest(EMULATOR_ACTION_TYPES.START_EMULATOR, startEmulation);
}

function* watchResumeEmulation() {
	yield takeLatest(EMULATOR_ACTION_TYPES.RESUME_EMULATOR, gridEmulation);
}

export default function* sagas() {
	yield all([
		watchDecayLogic(),
		watchStartEmulation(),
		watchResumeEmulation()
	]);
}
