import * as React from 'react';
import { IUpsChargeWidgetProps, IUpsChargeWidgetState } from '../interfaces/IUpsChargeWidget';
import GaugeChart from './GaugeChart';

class UpsChargeWidget extends React.PureComponent<IUpsChargeWidgetProps, IUpsChargeWidgetState> {
	render() {
		const { site, upsCharge } = this.props;

		return (
			<div className='ups-charge'>
				<div className='header'>
					<span>{ `${site} - UPS Charge Remaining` }</span>
				</div>

				<div className='content'>
					<div className='charge-count'>
						{ `${upsCharge} %` }
					</div>

					<div className='gauge'>
						<GaugeChart data={ upsCharge } />
					</div>
				</div>
			</div>
		);
	}
}

export default UpsChargeWidget;
