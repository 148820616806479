import * as React from 'react';
import App from './App';
import { IKineticGridProps } from '../interfaces/IKineticGrid';

class KineticGrid extends React.Component<IKineticGridProps, {}> {
	render() {
		return (
			<div id='kinetic-grid-container'>
				<App />
			</div>
		);
	}
}

export default KineticGrid;
