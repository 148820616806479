import * as React from 'react';
import classnames from 'classnames';
import { ITelemetry } from '../redux/types';
import { ITelemetryConsoleProps, ITelemetryConsoleState } from '../interfaces/ITelemetryConsole';

class TelemetryConsole extends React.Component<ITelemetryConsoleProps, ITelemetryConsoleState> {
	private readonly endRef: React.RefObject<HTMLInputElement>;

	constructor(props) {
		super(props);

		this.endRef = React.createRef();
	}

	componentDidMount () {
		this.scrollToBottom();
	}

	componentDidUpdate () {
		this.scrollToBottom();
	}

	scrollToBottom = () => {
		const { show } = this.props;

		if (show) {
			this.endRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}

	renderTelemetryStream = (telemetryStream: Array<ITelemetry>) => {
		return telemetryStream.map((telemetry, index) => {
			const {
				egress,
				ingress,
				latency,
				location,
				timestamp,
				upsCharge
			} = telemetry;
			const egressGbps = Math.round(egress / 13.33 * 10) / 10;
			const ingressGbps = Math.round(ingress / 13.33 * 10) / 10;
			const streamEntry = `TIME: ${timestamp} | SITE: ${location} | UPS_CHARGE: ${upsCharge}% | LATENCY: ${latency}μs | PORT_UTIL_EGRESS: ${egressGbps}Gbps | PORT_UTIL_INGRESS: ${ingressGbps}Gbps`;

			return (
				<div key={ index }>
					{ streamEntry }
				</div>
			);
		});
	}

	render() {
		const {
			onButtonClick,
			show,
			telemetryStream
		} = this.props;
		const componentClassNames = classnames('telemetry-console', { open: show });

		return (
			<div className={ componentClassNames }>
				<div
					className='console-button'
					onClick={ onButtonClick }>
					<i className='fas fa-terminal' />
				</div>

				<div className='stream-entries'>
					{ this.renderTelemetryStream(telemetryStream) }
					<div ref={ this.endRef } />
				</div>
			</div>
		);
	}
}

export default TelemetryConsole;
