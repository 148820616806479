import * as React from 'react';
import classnames from 'classnames';
import { DropdownButton, Spinner } from 'react-bootstrap';
import { IMaintenanceControlProps, IMaintenanceControlState } from '../interfaces/IMaintenanceControl';
import Toggle from './Toggle';

class MaintenanceControl extends React.Component<IMaintenanceControlProps, IMaintenanceControlState> {
	constructor(props) {
		super(props);

		this.state = {
			isControlOpen: false,
			isSitesDropdownOpen: false,
			selectedSite: undefined
		};
	}

	onControlButtonClick = (e) => {
		const { isControlOpen } = this.state;

		this.setState({
			isControlOpen: !isControlOpen
		});
	}

	onLatencyToggleClick = (site: string, value: boolean) => {
		const { setDegradingLatency } = this.props;

		setDegradingLatency(site, value);
	}

	onPowerToggleClick = (site: string, value: boolean) => {
		const { setDegradingPower } = this.props;

		setDegradingPower(site, value);
	}

	setSelectedSite = (selectedSite: string) => {
		this.setState({
			isSitesDropdownOpen: false,
			selectedSite
		});
	}

	toggleSitesDropdown = () => {
		const { isSitesDropdownOpen } = this.state;

		this.setState({
			isSitesDropdownOpen: !isSitesDropdownOpen
		});
	}

	renderControlButton = () => {
		const { isControlOpen } = this.state;

		if (isControlOpen) {
			return (
				<div
					className='control-button'
					onClick={ this.onControlButtonClick }>
					<i className='fas fa-angle-left dark' />
					<i className='fas fa-angle-left light' />
				</div>
			);
		}

		return (
			<div
				className='control-button'
				onClick={ this.onControlButtonClick }>
				<i className='fas fa-angle-right light' />
				<i className='fas fa-angle-right dark' />
			</div>
		);
	}

	renderControlOptions = () => {
		const { sites } = this.props;
		const { selectedSite } = this.state;
		const siteData = sites.filter((site) => {
			return site.slug === selectedSite;
		});

		if (siteData && siteData.length > 0) {
			const site = siteData[ 0 ];
			const {
				degradingLatency,
				degradingPower,
				receivingWorkload,
				slug,
				terminatingWorkload
			} = site;
			const toggleDisabled = receivingWorkload || terminatingWorkload;
			const powerTooltipMessage = degradingPower ? 'Toggle to restore POWER' : 'Toggle to trigger POWER failure';
			const latencyTooltipMessage = degradingLatency ? 'Toggle to reduce LATENCY' : 'Toggle to spike LATENCY';

			return (
				<div className='options'>
					<div className='options-header'>
						{ 'AVAILABLE OPTIONS' }
					</div>

					<div className='options'>
						<div className='option'>
							<div className='type'>
								<i className='fas fa-bolt' />
								{ 'Main Power' }
							</div>

							<div className='option-toggle'>
								{ toggleDisabled && <Spinner animation={ 'border' } /> }

								<Toggle
									checked={ !degradingPower }
									disabled={ receivingWorkload || terminatingWorkload }
									onToggleClick={ this.onPowerToggleClick.bind(this, slug, !degradingPower) }
									tooltipMessage={ powerTooltipMessage } />
							</div>
						</div>

						<div className='option'>
							<div className='type'>
								<i className='fas fa-spinner' />
								{ 'Spike Latency' }
							</div>

							<div className='option-toggle'>
								{ toggleDisabled && <Spinner animation={ 'border' } /> }

								<Toggle
									checked={ degradingLatency }
									disabled={ receivingWorkload || terminatingWorkload }
									onToggleClick={ this.onLatencyToggleClick.bind(this, slug, !degradingLatency) }
									tooltipMessage={ latencyTooltipMessage } />
							</div>
						</div>
					</div>
				</div>
			);
		}
	}

	renderSitesDropdown = () => {
		const { sites } = this.props;
		const { isSitesDropdownOpen, selectedSite } = this.state;

		if (sites && sites.length > 0) {
			return (
				<div className='select-site'>
					{ 'Select a Site' }

					<DropdownButton
						alignRight={ true }
						id='dropdown-button'
						className='sites-dropdown'
						onClick={ (e) => { e.stopPropagation(); } }
						onToggle={ this.toggleSitesDropdown }
						show={ isSitesDropdownOpen }
						title={ selectedSite || '...' }>
						{ this.renderSitesDropdownItems() }
					</DropdownButton>
				</div>
			);
		}
	}

	renderSitesDropdownItems = () => {
		const { sites } = this.props;
		const { selectedSite } = this.state;

		return sites.map((site) => {
			const { slug } = site;

			if (selectedSite === slug) {
				return null;
			}

			return (
				<li key={ slug }>
					<a onClick={ this.setSelectedSite.bind(this, slug) }>{ slug }</a>
				</li>
			);
		});
	}

	render() {
		const { isControlOpen } = this.state;
		const componentClassNames = classnames('maintenance-control', { open: isControlOpen });

		return (
			<div className={ componentClassNames }>
				<div className='header'>
					<div className='title'>
						{ 'Maintenance Control' }
					</div>

					{ this.renderControlButton() }
				</div>

				{ this.renderSitesDropdown() }
				{ this.renderControlOptions() }
			</div>
		);
	}
}

export default MaintenanceControl;
