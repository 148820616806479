import * as React from 'react';
import classnames from 'classnames';
import { IUpsStatusWidgetProps, IUpsStatusWidgetState } from '../interfaces/IUpsStatusWidget';

class UpsStatusWidget extends React.PureComponent<IUpsStatusWidgetProps, IUpsStatusWidgetState> {
	getPowerStatus = (status: string) => {
		switch (status) {
			case 'Battery Charged':
				return 'Grid/Shore Power';
			case 'Battery Charging':
				return 'UPS Charging';
			case 'Battery Engaged':
				return 'UPS Activated';
			default:
				return status;
		}
	}

	renderWidgetItems = (status: string) => {
		const statusClassNames = classnames('status-icon', {
			danger: (status === 'Battery Engaged'),
			success: (status === 'Battery Charged'),
			warning: (status === 'Battery Charging')
		});
		const iconClassNames = classnames('fas', {
			'fa-car-battery': (status === 'Battery Engaged'),
			'fa-check-circle': (status === 'Battery Charged'),
			'fa-exclamation-circle': (status === 'Battery Charging')
		});
		const powerStatus = this.getPowerStatus(status);

		return (
			<div className='content'>
				<div className='status'>
					{ powerStatus }
				</div>

				<div className={ statusClassNames }>
					<i className={ iconClassNames } />
				</div>
			</div>
		);
	}

	render() {
		const { site, upsStatus } = this.props;

		return (
			<div className='ups-status'>
				<div className='header'>
					<span>{ `${site} - Power Delivery` }</span>
				</div>

				{ this.renderWidgetItems(upsStatus) }
			</div>
		);
	}
}

export default UpsStatusWidget;
