import {
	DEPLOYMENTS_ACTION_TYPES,
	EMULATOR_ACTION_TYPES,
	MAPBOX_ACTION_TYPES,
	PODS_ACTION_TYPES,
	SITES_ACTION_TYPES,
	TELEMETRY_STREAM_ACTION_TYPES
} from './action_types';
import {
	IDeploymentsAction,
	IEmulatorAction,
	IMapboxAction,
	IPod,
	IPodsAction,
	ISitesAction,
	ITelemetry,
	ITelemetryStreamAction
} from './types';

// Deployments Actions

export function appendPod(deploymentSlug: string, podSlug: string): IDeploymentsAction {
	return {
		deploymentSlug,
		podSlug,
		type: DEPLOYMENTS_ACTION_TYPES.APPEND_POD
	};
}

export function popPod(deploymentSlug: string, podSlug: string): IDeploymentsAction {
	return {
		deploymentSlug,
		podSlug,
		type: DEPLOYMENTS_ACTION_TYPES.POP_POD
	};
}

// Emulator Actions

export function pauseEmulator(): IEmulatorAction {
	return {
		type: EMULATOR_ACTION_TYPES.PAUSE_EMULATOR
	};
}

export function resetEmulator(): IEmulatorAction {
	return {
		type: EMULATOR_ACTION_TYPES.RESET_EMULATOR
	};
}

export function resumeEmulator(): IEmulatorAction {
	return {
		type: EMULATOR_ACTION_TYPES.RESUME_EMULATOR
	};
}

export function startEmulator(): IEmulatorAction {
	return {
		type: EMULATOR_ACTION_TYPES.START_EMULATOR
	};
}

// Mapbox Actions

export function setMapRef(mapRef: any): IMapboxAction {
	return {
		mapRef,
		type: MAPBOX_ACTION_TYPES.SET_MAP_REF
	};
}

// Pods Actions

export function addPod(pod: IPod): IPodsAction {
	return {
		pod,
		type: PODS_ACTION_TYPES.ADD_POD
	};
}

export function removePod(podSlug: string): IPodsAction {
	return {
		podSlug,
		type: PODS_ACTION_TYPES.REMOVE_POD
	};
}

export function setPodAge(podSlug: string, age: number): IPodsAction {
	return {
		age,
		podSlug,
		type: PODS_ACTION_TYPES.SET_POD_AGE
	};
}

export function setPodRestarts(podSlug: string, restarts: number): IPodsAction {
	return {
		podSlug,
		restarts,
		type: PODS_ACTION_TYPES.SET_POD_RESTARTS
	};
}

export function setPodStatus(podSlug: string, status: string): IPodsAction {
	return {
		podSlug,
		status,
		type: PODS_ACTION_TYPES.SET_POD_STATUS
	};
}

// Sites Actions

export function appendNetworkLatencyTick(siteSlug: string, timestamp: string, latency: number): ISitesAction {
	return {
		latency,
		siteSlug,
		timestamp,
		type: SITES_ACTION_TYPES.APPEND_NETWORK_LATENCY_TICK
	};
}

export function appendPortUtilizationTick(siteSlug: string, timestamp: string, egress: number, ingress: number): ISitesAction {
	return {
		egress,
		ingress,
		siteSlug,
		timestamp,
		type: SITES_ACTION_TYPES.APPEND_PORT_UTILIZATION_TICK
	};
}

export function dischargeUps(siteSlug: string, tick: number, minCharge: number): ISitesAction {
	return {
		minCharge,
		siteSlug,
		tick,
		type: SITES_ACTION_TYPES.DISCHARGE_UPS
	};
}

export function rechargeUps(siteSlug: string, tick: number, maxCharge: number): ISitesAction {
	return {
		maxCharge,
		siteSlug,
		tick,
		type: SITES_ACTION_TYPES.RECHARGE_UPS
	};
}

export function setActive(siteSlug: string, active: boolean): ISitesAction {
	return {
		active,
		siteSlug,
		type: SITES_ACTION_TYPES.SET_ACTIVE
	};
}

export function setDegradingLatency(siteSlug: string, degrading: boolean): ISitesAction {
	return {
		degrading,
		siteSlug,
		type: SITES_ACTION_TYPES.SET_DEGRADING_LATENCY
	};
}

export function setDegradingPower(siteSlug: string, degrading: boolean): ISitesAction {
	return {
		degrading,
		siteSlug,
		type: SITES_ACTION_TYPES.SET_DEGRADING_POWER
	};
}

export function setReceivingWorkLoad(siteSlug: string, receivingWorkload: boolean): ISitesAction {
	return {
		receivingWorkload,
		siteSlug,
		type: SITES_ACTION_TYPES.SET_RECEIVING_WORKLOAD
	};
}

export function setTerminatingWorkLoad(siteSlug: string, terminatingWorkload: boolean): ISitesAction {
	return {
		siteSlug,
		terminatingWorkload,
		type: SITES_ACTION_TYPES.SET_TERMINATING_WORKLOAD
	};
}

export function setUpsCharge(siteSlug: string, charge: number): ISitesAction {
	return {
		charge,
		siteSlug,
		type: SITES_ACTION_TYPES.SET_UPS_CHARGE
	};
}

export function setUpsStatus(siteSlug: string, upsStatus: string): ISitesAction {
	return {
		siteSlug,
		type: SITES_ACTION_TYPES.SET_UPS_STATUS,
		upsStatus
	};
}

// Telemetry Stream Actions

export function appendTelemetryData(telemetry: ITelemetry): ITelemetryStreamAction {
	return {
		telemetry,
		type: TELEMETRY_STREAM_ACTION_TYPES.APPEND_TELEMETRY_DATA
	};
}

export function resetTelemetryStream(): ITelemetryStreamAction {
	return {
		type: TELEMETRY_STREAM_ACTION_TYPES.RESET_TELEMETRY_STREAM
	};
}
