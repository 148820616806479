export const CHARTS = {
	COLOR_CODES: [
		'#00C5DC',
		'#716ACA',
		'#27CA41',
		'#2B86DA',
		'#FFC750',
		'#D9553F',
		'#FF0000',
		'#800000'
	],
	GAUGE_CHART: {
		GRAPH_SIZE: {
			height: 60
		}
	},
	LINE_CHART: {
		GRAPH_MARGIN: {
			bottom: 10,
			left: 12,
			right: 36,
			top: 18
		},
		IS_ANIMATION_ACTIVE: false,
		LEGEND: {
			WRAPPER_STYLE: {
				paddingTop: '18px'
			}
		},
		LINE_DOT: false,
		MIN_TICK_GAP: 1,
		STROKE_DASH_ARRAY: '3 3',
		TOOLTIP: {
			ALLOW_ESCAPE_VIEW_BOX: {
				x: false,
				y: true
			},
			WRAPPER_STYLE: {
				zIndex: 100
			}
		},
		X_AXIS: {
			ANGLE: -20,
			DX: 15,
			TEXT_ANCHOR: 'end'
		},
		Y_AXIS: {
			WIDTH: 45
		}
	}
};
